@charset "UTF-8";

@font-face {
  font-family: "fsfont";
  src:url("fonts/fsfont.eot");
  src:url("fonts/fsfont.eot?#iefix") format("embedded-opentype"),
    url("fonts/fsfont.woff") format("woff"),
    url("fonts/fsfont.ttf") format("truetype"),
    url("fonts/fsfont.svg#fsfont") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "fsfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="fs-"]:before,
[class*=" fs-"]:before {
  font-family: "fsfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fs-smile-o:before {
  content: "\79";
}
.fs-frown-o:before {
  content: "\6e";
}
.fs-ban:before {
  content: "\63";
}
.fs-tools:before {
  content: "\6d";
}
.fs-exclamation-triangle:before {
  content: "\21";
}
.fs-fire:before {
  content: "\66";
}
.fs-wheelchair:before {
  content: "\64";
}
.fs-bell-o:before {
  content: "\61";
}
.fs-fire-extinguisher:before {
  content: "\65";
}
.fs-file-text-o:before {
  content: "\70";
}
.fs-times:before {
  content: "\78";
}
.fs-check-square-o:before {
  content: "\74";
}
.fs-building-o:before {
  content: "\62";
}
.fs-content-7:before {
  content: "\6f";
}
.fs-info-circle:before {
  content: "\69";
}
.fs-wallet:before {
  content: "\77";
}
.fs-industry:before {
  content: "\43";
}
.fs-map-marker:before {
  content: "\53";
}
.fs-user:before {
  content: "\75";
}
.fs-logout:before {
  content: "\6c";
}
.fs-nuclear:before {
  content: "\58";
}
.fs-help:before {
  content: "\3f";
}
.fs-nuclear-1:before {
  content: "\44";
}
.fs-no-smoking:before {
  content: "\73";
}
.fs-home:before {
  content: "\48";
}
.fs-tree:before {
  content: "\54";
}
.fs-square-o:before {
  content: "\71";
}
.fs-map-signs:before {
  content: "\72";
}
.fs-flash:before {
  content: "\45";
}
.fs-floppy-o:before {
  content: "\46";
}
.fs-key:before {
  content: "\6b";
}
.fs-clipboard:before {
  content: "\68";
}
.fs-sitemap:before {
  content: "\4f";
}
.fs-wrench:before {
  content: "\67";
}
.fs-folder-o:before {
  content: "\6a";
}
.fs-plus-circle:before {
  content: "\2b";
}
.fs-trash:before {
  content: "\2d";
}
.fs-pencil:before {
  content: "\2a";
}
.fs-lightbulb-shine:before {
  content: "\4c";
}
.fs-minus-square-o:before {
  content: "\4d";
}
.fs-camera:before {
  content: "\50";
}
.fs-paperclip:before {
  content: "\26";
}
.fs-bars:before {
  content: "\76";
}
.fs-arrow-1-left:before {
  content: "\3c";
}
.fs-arrow-1-right:before {
  content: "\3e";
}
.fs-plus-square-o:before {
  content: "\4e";
}
.fs-arrow-1-down:before {
  content: "\5c";
}
.fs-arrow-1-up:before {
  content: "\2f";
}
.fs-share-alt:before {
  content: "\56";
}
.fs-sort-alpha-asc:before {
  content: "\7a";
}
.fs-cutlery:before {
  content: "\4b";
}
.fs-docs:before {
  content: "\23";
}
.fs-room:before {
  content: "\42";
}
.fs-check:before {
  content: "\59";
}
.fs-star:before {
  content: "\52";
}
.fs-play:before {
  content: "\47";
}
.fs-star-o:before {
  content: "\49";
}
.fs-spinner:before {
  content: "\27f3";
}
.fs-eye:before {
  content: "\4a";
}
.fs-calculator:before {
  content: "\3d";
}
.fs-pencil-square-o:before {
  content: "\41";
}
.fs-calendar:before {
  content: "\51";
}
.fs-android-remove:before {
  content: "\5f";
}
.fs-angle-double-down:before {
  content: "\55";
}
.fs-angle-double-left:before {
  content: "\57";
}
.fs-angle-double-right:before {
  content: "\5a";
}
.fs-angle-double-up:before {
  content: "\30";
}
.fs-basement:before {
  content: "\24";
}
.fs-level:before {
  content: "\7c";
}
.fs-magic:before {
  content: "\25";
}
.fs-circle-o:before {
  content: "\2e";
}
.fs-cube:before {
  content: "\31";
}
.fs-hand-o-up:before {
  content: "\27";
}
.fs-hand-paper-o:before {
  content: "\2026";
}
.fs-shield:before {
  content: "\20ac";
}
.fs-user-shield:before {
  content: "\ac";
}
.fs-user-times:before {
  content: "\a3";
}
.fs-corridor:before {
  content: "\32";
}
.fs-room-1:before {
  content: "\33";
}
.fs-fire-precautions:before {
  content: "\34";
}
.fs-checklist:before {
  content: "\35";
}
.fs-oxygen:before {
  content: "\24c4";
}
.fs-log:before {
  content: "\24a7";
}
.fs-indent:before {
  content: "\37";
}
.fs-cog:before {
  content: "\40";
}
.fs-lock:before {
  content: "\238";
}
.fs-unlock-alt:before {
  content: "\239";
}
.fs-question:before {
  content: "\2753";
}
.fs-action-summary:before {
  content: "\36";
}
.fs-escape-route-check:before {
  content: "\2717";
}
.fs-open-plan:before {
  content: "\39";
}
.fs-fuel-sources:before {
  content: "\29";
}
.fs-fire-alarm:before {
  content: "\22";
}
.fs-fire-action-plan:before {
  content: "\2c";
}
.fs-enclosed-area:before {
  content: "\3a";
}
.fs-sprinkler-system:before {
  content: "\38";
}
.fs-arson-prevention:before {
  content: "\3b";
}
.fs-structural:before {
  content: "\28";
}
.fs-print:before {
  content: "\5b";
}
