/* Colours */

$fsred: #D21112;

$button-background: #ffffff;
$ok: #99ff99;
$warning: #ffe0b3;

$btncol: #000;
$btnbkcol: #eceeef;
$btnborder: rgb(206,212,218);
$btnbkcolhover: #ccc;

$stnhover: #212529;

$border: rgb(204, 204, 204);

$btndarkborder: #888;

$defaulttext: #000;
$bodybackground: #eee;
$rootbackground: #fff;

$RiskHighlight: #cacccd;

$SubSection: #fff; /*#f4f5f7;*/

/*$headingHighlightBackground: #fcfcfc; /*#e8f0f8*/; /*#cce5ff;*/
$headingHighlightBackground: #fff;
$highlightText: #007bff;
$alertText: #ffbf00;
$okText: #009900;

$input-group-border: #ced4da;

$laurablue: #0070C0;

$laurablue2: #338DCD;

$p1: #d93e38;
$p2: #cf6625;
$p3: #cf8825;


/* General */

/*textarea {
	min-height: 7rem;
}*/

/* button in place of a tag */
button.abutton {
	border: none;
	background: none;
	color: $highlightText;
	&:hover {
		text-decoration: underline;
	}

	&.sm {
		font-size: 0.8rem;
	}
}

/*Utils */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: $highlightText!important;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: $highlightText!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: $highlightText!important;
}

.right {
  float: right;
}

.hidden {
	display: none;
}

.bold {
	font-weight: bold;
}

.buttonhide {
	display: none;
}

input.form-control[type=text]:focus {
	background-color: #fff;
}

input.alertLevel {
	color: $p2;
}

.fs {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fs-spin {
  -webkit-animation: fs-spin 2s infinite linear;
  animation: fs-spin 2s infinite linear;
}
.fs-pulse {
  -webkit-animation: fs-spin 1s infinite steps(8);
  animation: fs-spin 1s infinite steps(8);
}
@-webkit-keyframes fs-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fs-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.fs-throb {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

.temphighlight{
    background-color: #fff;
    animation-name: animationName;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;    
    animation-play-state: running;
}

@keyframes animationName {
    0%  {background-color:lightcoral;}
    100%  {background-color: #fff;}
}

/* Select Search */

.select-search {
    /*width: 300px;*/
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

.select-search.is-disabled {
	opacity: 0.5;
}

.select-search__value {
	position: relative;
}

.select-search__value::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: calc(50% - 9px);
	right: 19px;
	width: 11px;
	height: 11px;
	transform: rotate(45deg);
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
	pointer-events: none;
}

.select-search__input {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: none;
	box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	line-height: 36px;
	-webkit-appearance: none;
}

.select-search:not(.is-disabled) .select-search__input {
	cursor: pointer;
}

.select-search--multiple .select-search__input {
	cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
	-webkit-appearance:none;
}

.select-search--multiple .select-search__input {
	border-radius: 3px 3px 0 0;
}

.select-search__input:hover {
	border-color: #2FCC8B;
}

.select-search__input:not([readonly]):focus {
	cursor: initial;
}

.select-search__select {
	background: #fff;
	box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
	position: absolute;
	z-index: 2;
	top: 44px;
	right: 0;
	left: 0;
	border-radius: 0 0 3px 3px;
	overflow: auto;
	max-height: 360px;
}

.select-search--multiple .select-search__select {
	height: 180px;
	overflow: auto;
	border-top: 1px solid #eee;
	border-radius: 0 0 3px 3px;
}

.select-search__options {
	list-style: none;
}

.select-search__row:not(:first-child) {
	border-top: 1px solid #eee;
}

.select-search__option {
	display: block;
	height: 36px;
	width: 100%;
	padding: 0 16px;
	background: #fff;
	border: none;
	outline: none;
	font-family: 'Noto Sans', sans-serif;
	font-size: 14px;
	text-align: left;
	cursor: pointer;
	overflow: hidden;
}

.select-search--multiple .select-search__option {
	height: 48px;
}

.select-search__option.is-selected {
	background: #2FCC8B;
	color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
	background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
	background: #2eb378;
	color: #fff;
}

.select-search__group-header {
	font-size: 10px;
	text-transform: uppercase;
	background: #eee;
	padding: 8px 16px;
}



/* Bootstrap Tweaks */

.input-group-text {
	white-space: normal;
	text-align: left;
}

/*.alert-primary {
	background-color: initial;
	border: none;
	color: $defaulttext;
}*/

div.input-group-addon {
	padding: 0;
	border: 1px solid $btnborder;

	>button {
		border-width: 0;
	}
}

/*ul.nav.nav-tabs {
	flex: 0 0 100%;
}*/

div.dropdown-multicol{
	width: 20em;
}

div.dropdown-row>.dropdown-item{
	display: inline-block;
	width: 30%;
}

div.dropdown-menu {
	background-color: $rootbackground;
	border: 1px solid $btnborder;
	float: none;

	&.wide { /* For long text strings */
		width: 80vw;
		>div.dropdown-item {
			white-space: break-spaces;
		}
	}
}

div.tab-pane {
	margin: 1em;
	background: $rootbackground;
}

.modalshow {
    opacity: 1;
    display: block;
}

.modalhide {
	display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}

div.Modal {
	&.oneline {
		>div.modal {
			>div.modal-dialog {
				margin: 0;
				>div.modal-content {
					width: 100vw;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					/*>div.card-block {
						height: 100vh;
					}*/
				}
			}
		}
	}
}

label.radio-addon {
	background-color: $button-background;
	/*border: 1px solid $btnborder;*/
}

.form-group {
	margin-bottom: 0.5rem;
}

.dropdown-menu {
	>label {
		/*padding: 0;*/
	}
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.form-control {
	font-size: inherit;
	height: auto;
}

div.childblock {
  transition: all 0.3s;
}

/*
.input-group {

	&:first-child {
		border-top-left-radius: 0.25rem;
	}

	+.input-group {
		> * {
			border-top: 0;
			> *:not(.dropdown-menu) {
				border-top: 0;
				> * {
					border-top: 0;
				}
			}
		}
		>.input-group {
			>.input-group-append {
				> * {

					
					> * {
						border-top: 0;

						> * {
							border-top: 0;
						}
					}
				}
			}
		}
	}

	>.input-group {
		padding: 0;
		border: 0;
		border-radius: 0px;

		>.form-control {
			border-radius: 0px;
		}
	}

	>.input-group-prepend {
		> * {
			border-radius: 0;
		}
	}

	>.input-group-append {

		> *:not(:last-child) {
			border-right: 0;

			> * {
				border-right: 0;

			}
		}
	}

	>.input-group {

		>.input-group-append {
			> * {
				> * {
					border-right: 0;
				}
			}
		}
	}

	.Selectable {
		padding: 0;
		border: 0;
	}
}
*/

.input-group {
	border: 1px solid $btnbkcol;
	border-width: 1px 0;

	>*:not(.Modal) {
		border: 0;
		border-radius: 0;
		background-color: inherit;
		border-left: 0;
		border-right: 0;

		>*:not(label):not(.dropdown-menu) {
			border: 0;
			border-radius: 0;
			background-color: inherit;
			border-left: 0;
			border-right: 0;

			>*:not(.dropdown-menu) {
				border: 0;
				background-color: inherit;
				border-left: 0;
				border-right: 0;
			}
		}
	}

	+ .input-group {
		border-top-width: 0;
	}

	&.standalone {
		border-width: 0;

		margin: 1.5em 0;

		display: flex;
  		justify-content: center;
	}

}


.input-group-addon {
	font-size: inherit;
	>.btn {
		height: 100%;
	}
}

.btn {
	font-size: inherit;
}

.breadcrumb {
	padding: .375rem .75rem;
}

.alert {
	border-radius: 0;
}

/*div.btn-group {
	>div.Modal {
		>div.modal {
			background-color: initial;
		}
	}
}*/

.form-container {display: flex}



/* Firesmart css */

body {
  padding: 2.5em 0 1.5em;
  /*line-height: 1rem;*/
}

nav.navbar {
		background-color: $headingHighlightBackground;

		padding: 3px 0 0 0;

		margin: 0;

		height: 3rem;

		>ul {
			position: absolute;
			bottom: 0;
			background-color: $headingHighlightBackground;
		}
}

.title {
	font-size: 110%;
}

span.logo {
	font-family: "Noto Sans JP";
  line-height: 2.6em;
  color: #222;

	>span {
	  display: inline-block;
	  width: 2.8em;
	  height: 2.8em;
	  border-radius: 50%;
	  
	  background: rgb(136,8,8);
	  background: linear-gradient(327deg, rgba(136,8,8,1) 0%, rgba(209,11,11,1) 58%, rgba(255,255,255,1) 95%);
  
	  color: #fff;
	}
}

div.header-logo {
	font-size: 3vw;
	transition: all 0.3s;
}

.topinfobar {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding-left: 1em;
	z-index: 200;
	padding-top: 0.1em;
	background-color: #fff;
	border-top: 2px solid #ccc;

	>span {
		font-size: 0.8em;
	}
	>span+span {
		padding-left: 1em;
	}
}

/* Cachebuster */
button.cb {
	position: fixed;
	bottom: 2em;
	right: 0;
	border: none;
	background: none;
}

.fs-unlock-alt {
	color: $fsred;
}

div.Hider {
	
	&.open {
		display: initial;
	}
	&.closed {
		display: none;
	}

}

.FButton {
	padding: 0.1rem 0.2rem;
	white-space: nowrap;
	border-radius: 0;
	color: $btndarkborder;
	background-color: inherit;
	text-align: center;
	position: relative;

	display: inline-flex;
	flex-direction: column;
	align-items: center;

	&.vcenter {
		justify-content: center;
	}

	/*>.FButtonOverlay {
		
	}*/

	&:hover:not([disabled]) {
		background-color: inherit;
		>.FButtonIcon, >.FButtonText, >.FButtonOverlay {
			color: $btncol;
		}
	}
	&[disabled] {
		cursor: default;

		&:hover {
			color: $btndarkborder;
		}
	}

	&.active {
		background-color: inherit;
		border: solid 1px $btncol;
		border-radius: 0.25rem;

		>.FButtonIcon, >.FButtonText, >.FButtonOverlay {
			color: $btncol;
		}
		&:hover {
			/*background-color: $btnbkcol;*/
			
			>.FButtonIcon, >.FButtonText, >.FButtonOverlay {
				color: $btndarkborder;
			}
		}
	}

	>.FButtonIcon {
		display: block;
		height: 2.2rem;
		&::before {
			font-size: 2rem;
		}
		>*::before{
			font-size: 2rem;
		}

		>span {
			/*line-height: 2rem;*/
		}
	}

	>.FButtonText {
		display: block;
		font-size: 0.7rem;
		line-height: 1rem;
	}

	>.FButtonOverlay {
		background-color: transparent!important;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 2rem;
		top: 0; /* calc(50% - 1.5rem); */
		left: 0;
		padding: 0;
		color: $btndarkborder;
		font-weight: bold;
		text-shadow: 
			-1px -1px 0 $rootbackground,
			1px -1px 0  $rootbackground,
			-1px 1px 0  $rootbackground,
			1px 1px 0  $rootbackground;
	}

	&.image {
		min-width: auto;

		&:hover {
			>*{
				opacity: 0.5;
			}
		}
	}

	&.large {
		>.FButtonIcon {
			height: 3rem;
			&::before {
				font-size: 3rem;
			}
			>*::before{
				font-size: 3rem;
			}
	
			>span {
				line-height: 3rem;
			}
		}

		>.FButtonText {
			font-size: 1rem;
			line-height: 1.5rem;
		}

		>.FButtonOverlay {
			position: relative;
			width: auto;
			height: auto;
			top: -4rem;
			font-size: 2rem;
			margin-bottom: -2rem;
		}
	}

	&.small {
		>.FButtonIcon {
			height: 1rem;
			&::before {
				font-size: 1rem;
			}
			>*::before{
				font-size: 1rem;
			}
	
			>span {
				line-height: 1rem;
			}
		}

		>.FButtonText {
			font-size: 1rem;
			line-height: 1rem;
		}

		>.FButtonOverlay {
			position: relative;
			width: auto;
			height: auto;
			top: -1.5rem;
			font-size: 0.8rem;
			margin-bottom: -2rem;
		}
	}
}

button.AddButton {
	border: none;
	background: none;
	margin: 0.2rem 0;

	>span {
		display: inline-block;
		vertical-align: middle;
		color: $btndarkborder;
		font-size: 1.5rem;
		margin-right: 0.3rem;
	}

	>div {
		display: inline-block;
		vertical-align: middle;
		color: $laurablue;
		font-size: 1.1rem;
	}

	&:hover {
		>* {
			filter: brightness(50%);
		}
	}
}

button.IcondropButton {
	display: inline-block;
	margin: 0.1rem;
	width: 5rem;
	height: 5rem;

	>div {
		font-size: 2rem;
	}

	>div.iconname {
		margin-top: 0.3rem;
		font-size: 0.6rem;
		line-height: 0.6rem;
		height: 1.5rem;
	}
}

.FInfo {
	padding: 0.1rem 0.2rem;
	white-space: nowrap;
	border-radius: 0;
	color: $btndarkborder;
	background-color: inherit;
	text-align: center;

	&.fullsize {
		>.FInfoMain {
			line-height: 1.9rem;
			font-size: 1.5rem;

			>span {
				padding: 0.25rem;
			}

			>input {
				padding: 0 0.5rem;
			}
		}
	}

	>.FInfoMain {
		display: block;
		height: 2.2rem;

		&.text {
			height: 1.3rem;		
		}

		>span {
			color: $btndarkborder;
			border: 0;
			display: inline;
			padding: 0.25rem;
		}

		>span.EditableA {
			display: inline-flex;
		}

		.YesNoYes {
			color: $okText;
		}
		
		.YesNoNo {
			color: $alertText;
		}
		
		>a{
			border: 0;
		}
		
		>span[contenteditable=true] {
		color: #000;
		}
		
	}

	>.FInfoTitle {
	display: block;
	font-size: 0.7rem;
	line-height: 1rem;

		&.titleLeft {
			text-align: left;
			margin-left: 0.5em;
		}

		&.titleRight {
			text-align: right;
			margin-right: 0.5em;
		}
	}

	&.edit {
		background-color: $rootbackground;
	}
}

.YesNoYes {
	color: $okText;
}

.YesNoNo {
	color: $alertText;
}

span.FormYesNo {
	/*display: block;*/
	/*width: 4.5rem;*/
	height: 2.5rem;

	.fullsize {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	>button:hover {
		border: solid 1px $btndarkborder;
	}
}

div.FInfo.escapelength {
	>span.FInfoMain {
		>input {
			display: inline-block;
			width: 3.5rem;
			padding: 0.1rem;
		}
		>span {
			display: inline-block;
			font-size: 1.5rem;
			line-height: 2rem;
		}
	}
}

.BSDrop.dropdown, .BSDrop.dropup {
	>button {
		min-width: 2rem;
	}


	>div.dropdown-menu {
		>.BSDropItem {
			
			>button.dropdown-button {
				width: 100%;
				border: none;
				background-color: inherit;
				text-align: left;

				&.active {
					background-color: #ccc;
				}
			}

			>button.addon-button {
				/*position: absolute;
				right: 0.1rem;*/

				margin-left: -0.5rem;

				border: none;
				background-color: inherit;
				padding: 0.1rem;
				color: $btnborder;
				&:hover {
					color: $defaulttext;
				}
			}
		}

		>div.input-group {
			padding: 0;
		}
	}
}

.CircleCheck {
	padding: 0.1rem 0.2rem;
	text-align: center;
	display: block;

	>label {
		
		display: block;
		margin: auto;
		text-align: center;
		cursor: pointer;

		>input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;

			&:checked {
				& ~ .cc-icon>span::before {
					color: inherit;
				}
			}

			/*&:checked:hover {
				& ~ .cc-icon {
					visibility: hidden;
				}
			}*/
		}

		>span.cc-icon {
			display: block;
			/*visibility: hidden;*/
			line-height: 2.2rem;
			color: $btndarkborder;


			>span {
				&::before {
					height: 2.2rem;
					width: 2.2rem;
					background-color: $rootbackground!important;
					display: inline-block;
					padding: 0.3rem;
					border: solid 2px $btndarkborder!important;
					border-radius: 50%;
					color: rgba(0,0,0,0)
				}

				&:hover::before {
					border-color: #000!important;
				}
			}


			&.noCheck {
				visibility: visible;
			}

			>span {
				font-size: 1.3rem;
				vertical-align: top;
			}

			&.active {
				/*color: $highlightText;*/
			}

			&.alertText {
				color: $alertText;
			}
			&.ok {
				color: $okText;
			}


		}
	}

	>span.cc-title {
		line-height: 0.7rem;
		font-size: 0.7rem;
	}

	&.inline {
		display: inline;

		>label {
			display: inline-block;
		}

		>span.cc-title {
			margin-left: 0.5rem;
			line-height: 2rem;
			font-size: 1rem;
		}
	}

	&.disabled {

		>label {
			border: solid 2px $btnbkcol!important;
			cursor: default;
	
			&:hover {
				border-color: $btnbkcol!important;
			}
		}
	}
}


.CircleIcon {
	padding: 0.1rem 0.2rem;
	text-align: center;
	display: block;

	>div {
		height: 2rem;
		width: 2rem;
		border: solid 2px $btndarkborder!important;
		border-radius: 50%;
		display: block;
		margin: auto;
		text-align: center;
		cursor: pointer;
		background-color: $rootbackground!important;

		&:hover {
			/*border-color: #000!important;*/
		}

		>span.cc-icon {
			display: block;
			/*visibility: hidden;*/
			line-height: 2.2rem;
			color: $btndarkborder;

			>span {
				font-size: 1.3rem;
				vertical-align: top;
			}

			&.active {
				/*color: $highlightText;*/
			}

			&.alertText {
				color: $alertText;
			}
			&.ok {
				color: $okText;
			}
		}
	}

	>span.cc-title {
		line-height: 0.7rem;
		font-size: 0.7rem;
	}

	&.inline {
		display: inline;

		>label {
			display: inline-block;
		}

		>span.cc-title {
			margin-left: 0.5rem;
			line-height: 2rem;
			font-size: 1rem;
		}
	}

	&.disabled {

		>div {
			border: solid 2px $btnbkcol!important;
			cursor: default;
	
			&:hover {
				border-color: $btnbkcol!important;
			}
		}
	}
}

.pass {
	background-color: $ok;
}

.fail {
	background-color: $warning;
}

/*
.navbar  {
	padding-bottom: 0;
	background-color: #fff;
}
*/

.btn, button {
	cursor: pointer;
}

/*
.crossed {
	text-decoration: line-through;
}
*/

.bold {
	font-weight: bold;
}

.notice {
	font-size: 1.2em;
	border: 2px solid #F00;
	padding: 0.5em;
	margin: 0.5em 0;

}

.floatbutton {
	float: left;
	font-size: 1.5rem;
	line-height: 1;
	color: #888;
	text-shadow: 0 1px 0 #fff;
	opacity: .5;
	border: none;
	background: none;
	padding: 0;

	&.right {
		float: right;
	}

	&:hover {
		opacity: 1;
	}
}

span.placeholder, p.placeholder {
	padding-top: 0.5rem;
	color: #ccc;
	font-size: 1.5rem;
	text-align: center;
}

div.card-block {
	white-space: normal;
}

//* Hides left border for btn-group class elements in input-groups */
div.input-group-prepend, div.input-group-append {
	>div.btn-group {
		/*margin-left: -1px;*/
	}

	>.btn-group + .btn, >.btn-group + .btn-group, >.btn + .btn-group {
		/*margin-left: -1px;*/
	}
}

/* Survey Edit button */
div.surveyedit {
	display: inline-block;
	position: fixed;
	top:3.5rem;
	right: 1rem;
	z-index: 1040;

	>label.active {
		background-color: #ff0000;
	}
}

div.rightButton {
	text-align: right;
}

#rightbuttons {
	position: fixed;
	top: 0.2em;
	right: 0.5em;
	z-index: 1040;

}

div.NextButton {
	text-align: center;
	margin-top: 1em;
}

button.bluebutton {
	color: #fff;
	background-color: $laurablue;
	cursor: default;

	&:hover:enabled {
		color: #fff;
		background-color: $laurablue2;
		cursor: pointer;
	}
	&:active {
		background-color: $laurablue;
	}
}

button.bluebutton2 {
	color: #fff;
	background-color: $laurablue2;

	transition: all 0.25s ease;

	&:hover:enabled {
		color: #fff;
		background-color: $laurablue2;
		filter: brightness(80%);

		>* {
			filter: none;
		}
	}
	&:active {
		background-color: $laurablue2;
	}
}


div.card.card-edit {
	/*margin-top: 0.5em;*/

	border: 1px solid $btnbkcol;
	border-radius: .25rem .25rem 0 0;

	vertical-align: top;

	text-align: left;

	div.card-header, div.card-footer {
		padding: 0;
		background-color: $SubSection;
		border-bottom: 1px solid $btnborder;

		&:first-child, &:last-child {
			border-radius: 0 0 0 0;
		}

		&.input-group {
			/*min-height: 3.5rem;*/
			border: none;

			>.input-group-btn>button {
				border: none;
			}
			>:first-child {
				padding-left: 0.5vw;
				border-left: none;
				border-radius: 0;
			}
			>:last-child {
				border-right: none;
				border-radius: 0;
			}
			>a, >div{
				background-color: $SubSection;
			}
			>* {
				border-top: none;
				border-bottom: none;
				/*padding: .25rem .5rem;*/
			}

			div.btn {
				padding: 0;
			}

			>.input-group-append {
				/*position: absolute;
				right: 0;*/

				>.btn, >button {
					border-top: 0;
					border-bottom: 0;
				}
				>div.btn {
					padding: 0;
				}
				>div {
					>.btn, >button {
						height: 100%;
						border-top: none;
						border-bottom: none;
						/*border-right: none;*/
					}
					&:not(:first-child) {
						>.btn, >button {
							/*border-left: none;*/
						}
					}
				}
				>:last-child {
					border-right: none;
					border-radius: 0;
				}
			}
			>.input-group-prepend {
				>.btn, >button {
					border-top: 0;
					border-bottom: 0;
				}
				>div.btn {
					padding: 0;
				}
				>div {
					>.btn, >button {
						height: 100%;
						border-top: none;
						border-bottom: none;
						/*border-left: none;*/
					}
					&:not(:last-child):not(.btn-primary) {
						>.btn, >button {
							border-right: none;
						}
					}
				}
				>:first-child {
					border-left: none;
					border-radius: 0;
				}
			}

		}
		> a[contenteditable=true] {
			background-color: #fff;
		}
		/*.title {
			font-size: 1.1em;
		}*/
		span.title {
			/*background-color:  #eceeef;*/
		}
		/*.icon {
			padding: .5rem .75rem;
		}*/
	}

	.icon {
		font-size: 2rem;
		line-height: 2.5rem;
		padding: 0.1rem;
		color: $btndarkborder;
		/*padding: .5rem .75rem;*/
		/*margin: -1px;*/
	}

	> div.card-block {
		padding: 0;
	}

	> div.card-footer {
		text-align: right;
		background-color: #eceeef;
	}

}

div.modal {

	>div.modal-dialog>div.card {
		>div.card-block {
			padding: 0.5em 1em;
		}
	}
}

div.Login {
	text-align: center;
	>img {
		width: 10em;
		margin-bottom: 1em;
	}

	max-width: 25em;
	margin: 1em auto;

	> div.Login-main {
		text-align: left;

		label {
			font-size: 0.9em;
			margin-top: 0.5em;
		}
	}
	
	> div.Login-footer {
		text-align: right;
		margin-top: 1em;
	}

	> div.Login-subfooter {
		text-align: left;
		font-size: 0.8em;
		> a {
			color: #999;
		}
	}
}

/*div.SitePane {
	>div.SiteInfo, >div.Survey {*/
		div.SurveySections {
			>div.card.card-edit {
				border: none;
				>div.input-group.card-header {
					border: 1px solid $btnbkcol;
				}
			}
		}
	/*}
}*/

/*button.SurveySectionStickyButton {
    position: sticky;
    top: 7rem;
    background-color: #fff;
	padding: 0.1em 0.75em;
	z-index: 5;
	border: 1px solid $input-group-border;
	border-radius: 2em;
	line-height: 2em;
	&:hover{
		border-color: $btndarkborder;
	}

	>span:before {
		line-height: 1.5em;
	}
}*/

button.stickyButton {
    position: sticky;
    top: 7rem;
    background-color: #fff;

	z-index: 5;
	&:hover{
		border-color: $btndarkborder;
	}
}

button.outlineButton {
	padding: 0.1em 0.75em;
	border: 1px solid $input-group-border;
	border-radius: 2em;
	line-height: 2em;
	&:hover{
		border-color: $btndarkborder;
	}
	>span:before {
		line-height: 1.5em;
	}
}

div.LocationSurvey {

	.areanew {
		margin-top: 0.5rem;
		margin-right: 0.5rem;
		text-align: right;
	}

	div.LocationBuilding {
		div.input-group {
			border: 0;
		}
	}

}

div.Location {

	.FInfo {
		border-left: solid 1px $btnborder;
		min-width: 10rem;

		>.FInfoMain {
			>.dropdown {
				width: 100%;

				>button.dropdown-toggle {
					width: 100%;
					margin: 0 1.5rem;

					&:after {
						position: absolute;
						top: 1.5em;
						/*content: none;*/
					}

					>span {						
						width: 100%;
					}
				}

				>button.cancel {
					position: absolute;
					right: 0.1rem;
					top: 0.1rem;

					&:hover {
						color: $defaulttext;
					}
				}
			}
			>input {
				width: 12rem;
			}
		}
	}
	>*:last-child {
		border-right: solid 1px $btnborder;
	}
}

/*div.SurveySections {
	>:first-child {
		opacity: .99;
	}
}*/

div.SurveySection.card.card-edit {
	/*border: 1px solid #ccc;
	border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;*/

	margin-top: 0.6em;

	/*z-index: 1;*/

	>:first-child {
		>* {
			/* cursor: pointer; */
		}
	}

	&.SSempty {
		>:first-child {
			>* {
				cursor: default;
			}
		}
	}

	> div.card-header {
		position: sticky;
		top: 3rem;
		z-index: 10;
		min-height: 3.5rem;

		> div.input-group-prepend {
			cursor: pointer;
		}

		> * {
			/*background-color: #cce5ff!important;*/

			> * {
				/*background-color: #cce5ff;*/
			}
		}

		&.input-group> * {
			background-color: $headingHighlightBackground;
		}

		&.live>div {
			/*background-color: #eceeef;*/
			/*font-size: 1.1em;*/
			/*border: none;*/
		}

		button {
			border-radius: 0;
			/*border: none;*/
		}

		.form-control {
			/*border: none;*/
		}

		div.title {
			/*padding-left: 0;*/
			cursor: pointer;
		}

		&.input-group {

		}
	}

	&.SubSection {
		border-radius: 0;

		> div.card-header {
			> * {
				background-color: #fff;
	
				> * {
					background-color: #fff;
				}
			}
		}
	}

	/*&.inactive {
		> div.card-header {
			> * {
				background-color: $btnborder!important;
				> * {
					background-color: $btnborder;
				}
			}
		}
	}*/

	> div.card-block {
		padding: 0 0 0 0;
		/*margin:-1px;*/
		&.hide {
			display:none;
		}

		border-bottom: 1px solid $btnbkcol;

	}

	div.SurveySectionModal {
		label.radio-addon {
			width: 100%;
			text-align: left;
			/*margin: -1px;*/
		}
	}
}

/*div.sectionIntroCard {
	max-width: 30rem;

	margin-bottom: 1rem;

	margin-left: 1rem;
	margin-right: 1rem;

	>div.input-group.card-header {
		padding-left: 1rem;
	}

	div.AddEscapeRoute-help {
		float: right;
	}

	>div.card-body {
		>div.input-group {
			border: 0;
			>div.input-group-prepend {
				min-width: 11rem;
			}
		}
	}

}*/

div.addWizard {
	>div.input-group {
		border: 0;
		>div.input-group-prepend {
			min-width: 11rem;
		}
	}
}

.expander {
	border: none;
	background: none;
	padding: 0;
	color: $btndarkborder;
	width: 100%;
	display: block;

	&:hover {
		text-decoration: none;
	}
}

div.input-group.card-header {
	.ztitle {
		font-size: 1.2em;
	}
}

div.Risk {
	/*margin: 0.5em -1px 0 -1px;*/
	
	/*margin-top: 0.5rem;*/

	.btn {
		border-radius: 0;
	}

	>div.card {
		border: 0;

		>div.card-header {

			padding: 0;
			/*margin: -1px -1px 0 -1px;*/
			border: none;

			&.liverisk {
				cursor: pointer;

				>.input-group>span.ztitle {
					/*color: $highlightText;*/

				}

				&:hover {
					background-color: $headingHighlightBackground;
				}
			}

			>div.input-group {
				min-height: 3rem;
				margin-bottom: -1px;

				&:not(.open) {
					/*border-bottom: 0;*/
				}

				>*:first-child {
					>*:first-child {
						border-left: 0;
					}
				}
			
				>*:last-child {

					>*:last-child {
						>*:first-child {
							border-right: 0;
						}
					}
				}
			}
		}

		>div.card-block {
			padding: 0;
			/*margin: -1px;*/
		}
	}

	.input-group-addon {
		border-radius: 0;
	}

	&.risk-highlight {
		>div.card {
			>div.card-header {
				background-color: $SubSection;
			}
		}
	}
}

div.Measure, div.Audit {
	>*:first-child {
		>*:first-child {
			border-left: 0;
		}
	}

	>*:last-child {
		>*:last-child {
			>*:first-child {
				border-right: 0;
			}
		}
	}
}


div.Measure {

	border: none;

	/*margin: -1px;*/

	/*&.Measure-user {
		>div.input-group>div.Measurename {
			font-style: italic;
		}
	}*/

	div,span,input {
		border-radius: 0;
	}

	/*.action_1 { 
		background-color: $ok;
		font-weight: bold;
	}
	.action_2 { 
		font-weight: bold;
		&.level_1 { 
			background-color: #ffffcc;
		}
		&.level_2 { 
			background-color: $warning;
		}
		&.level_3 { 
			background-color: #ffb3b3;
		}
		&.priority_3 {
			background-color: #ffffcc;
		}
		&.priority_2 { 
			background-color: $warning;
		}
		&.priority_1 { 
			background-color: #ffb3b3;
		}
	}*/

	.input-group-addon {
		border-radius: 0;
	}

	button {
		border-radius: 0;	
	}

	.rating {
		float: right;
	}

	>div.input-group {

		>.input-group-prepend {

		}

		>.input-group-append {

			>div {
				>.btn, >button {

				}

				/*&:not(:first-child) {
					>.btn, >button {
						border-left: 0;
					}
				}

				&:not(:last-child) {
					>.btn, >button {
						border-right: 0;
					}	
				}*/
			}
		}


		div.input-group-addon {
			&.icon {
				/*font-size: 1.1em;*/
				padding: .5rem .75rem;
			}
		}

		.Measurename {
			border-radius: 0;

			a {
				width: 100%;
				height: 100%;
			}
		}

		/*>div.input-group.input-group-addon.ProgressiveDrop {
			background-color: #fff;

			>span.input-group-addon {
				border-width: 0;
			}
				>ol.breadcrumb {
					border-width: 0 0 0 1px;
					border-radius:0;
					margin: 0 0 0 -1px;
					background-color: transparent;
				}
				>div.dropdown {
					height: 100%;
					button {
						height: 100%;
					}
				}
		}*/
	}

	.action, >div.input-group>.action {
		/*font-weight: bold;*/
		/*background-color: $warning;*/
		&.closed {
			/*background-color: $ok;*/
		}
	}

	.inplace, >div.input-group>.inplace {
		/*font-weight: bold;*/
		/*background-color: $ok;*/
	}
}

div.ItemGroup {
	/*border: 1px solid $border;*/
	border-top: 1px solid $border;
}

div.ProgressiveDrop {
	background-color: #fff;
	border-radius: 0;
	padding: 0;

	>span.input-group-addon {
		border-top: 0;
		border-bottom: 0;
		padding: 0 0.5em;
	}
	>ol.breadcrumb {
		border-width: 0 0 0 1px;
		border-radius:0;
		margin: 0 0 0 -1px;
		background-color: transparent;
		font-weight: inherit;
		padding: 0.375rem 0.75rem;
	}
	>div.dropdown {
		height: 100%;
		margin: 0 0.5em;
		>button {
			height: 100%;
			border-radius: 0;
			margin: -1px 0 -1px 0;
			padding: 0 0.5em;
		}
	}
}

div.ValueField {

	border: none;
	
	>.form-control {
		/*border-left: 1px solid $btnborder;*/
	}

	>.input-group-prepend {
		> *:first-child {
			min-width: 11rem;
			border-left: 0;
		}
	}

	>div.input-group {
		>div.input-group.form-control {
			padding: 0;
			>input, span {
				border-radius: 0;
				/*margin: -1px;*/
			}
		}

		>.input-group-addon {
			border-radius: 0;
			&.ValueField-title {
				white-space: nowrap;
				padding: 0 0.5em;
				flex: 0 0 15rem;
			}

			>input {
				border-width: 0 1px 0 0;
				border-radius: 0;
			}

			>.input-group-addon {
				border-width: 0;
				border-radius: 0;
			}
		}
	}
}

div.btn-group.ZoomImage {
	>button.btn.FButton {
		padding: 0;
		height: 3rem;

		&.big {
			height: 8rem;
		}

		>span.FButtonIcon {
			height: 100%;
			>img {
				height: 100%;
				max-height: 90vh;
				max-width: 90vw;
			}
		}
	}

	div.card-block {
		text-align: center;

		img {
			/*width: 100%;*/
			max-height: 100%;
			max-width: 100%;
		}
	}
}

div.FormImage {
	>.form-control {
		>img {
			height: 3rem;
		}
	}
	label {
		vertical-align: middle;
	}
}

div.ProgressiveDrop {
	>ol.breadcrumb{
		margin-bottom: 0;
	}
}

div.CalcField {
	&.form-control {
		border: 0;
	}
	padding: 0;
	input.form-control, div {
		/*border-radius: 0;*/
	}

	>input.form-control {
		/*width: 8rem;*/
		text-align: right;
	}

	div {
		span {
			border-radius: 0;
		}
	}

	.input-group-append {
		>.input-group-text {
			color: $btndarkborder;
		}
	}
}

div.ExtinguisherCategory {
	margin-bottom: 1em;
	border-radius: 4px 4px 0 0;

	div.extcategory, div.extrating {
		/*margin: -1px;*/
		>* {
			border-radius:0;
		}
	}
}

/*div.ECLocationLevel {
  border-bottom: 1px solid $input-group-border;
}*/

div.ExtinguisherStandards {
	div.extcategory {
		/*margin: -1px;*/
		>* {
			border-radius:0;
		}
	}
}

/*div.ecLocationBuilding.Risk {
	>.card {
		>.card-header {
			cursor: pointer;
		}
	}
}*/

.shortfall {
	>.title {
		padding: 0.5rem 1rem;
	}
	>.shortfall-detail {
		padding: 0.5rem 1rem;
	}
}

.toplevel {
	color: $laurablue2;
}

div.LocationBuilding {
	>div.input-group.card-header.edit {
		>div.form-control {
			
			>span {
				border: 0!important;
				&:focus-within {
					background-color: #fff;
				}
			}
		}
	}


	div.LocationLevel {
		>div.card {
			>div.card-header {
				background-color: #fff;
				>div.input-group {
					a {
						font-weight: bold;
					}
				}
			}
		}
	}
}

div.ecLocationBuilding {

	>div.card.card-edit {
		>div.card-header {
			cursor: pointer;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;

		.controls {
			>* {
				display: inline-block;
			}
			white-space: nowrap;
			text-align: center;
		}

		.small {
			font-size: 0.7em;
		}

		.form-control, .input-group {
			border: none;
		}

		.CalcField {
			>.input-group-prepend {
				display: none;
			}
		}

		th {
			text-align: center;
			padding: 0.3rem;
		}

		td.extlist {

			>* {
				vertical-align:top;
				display: inline-block;
				border-right: 1px solid $btnborder;
				&:last-child {
					border: none;
				}	
			}
			

		}

		.summary {

			th {
				text-align: left;
			}
		}

	}

	table, td, th {
		border: 1px solid $btnborder;
	}
}

.ecLocationLevel {
	.FInfo {
		border-right: 1px solid $btnborder;

		&.shortfall {

			>.FInfoMain>span {
				color: $alertText;
			}
		}

		&.noshortfall {

			>.FInfoMain>span {
				color: $okText;
			}
		}
	}

	label {
		&.shortfall {
			>span {
				color: $alertText;
			}
		}

		&.noshortfall {
			>span {
				color: $okText;
			}
		}
	}
}

div.AttachModal, div.MDModal, div.measureadd, div.MeasureTextModal, div.RiskTextModal {
	padding: 0;

	> button {
		/* border: none;*/
	}

	div.modal-content {
		text-align:left;
	}
}


div.ECLL-heading.input-group {
	border: 0;
}

div.ECLL-body {
	.GreyBox {
		min-height: 6rem;
	}
}

div.sectionTitle {
	border: none;
	margin-top: 0.75rem;
	margin-bottom: 0.25rem;
	padding: 0;
}


div.docpreview {

	>div.AttFile {
		display: inline-block;
		vertical-align: top;
		margin: 0.5rem;

		text-align: center;

		/*width: 18em;*/

		a:hover {
			text-decoration: none;
		}

		>button {
			background-color: transparent;
			border: none;

			.previewImg {
				display: block;
				margin-left: auto;
				margin-right: auto;
				border: 0;
				background: none;
				text-align: center;
			}
			div.filename {
				margin-top: 0.75em;
			}

			&:hover {
				opacity: 0.5;
			}
		}

		span.icon {
			font-size: 3em;
		}

		/*.filename {
			display: inline-block;
			width: 100%;
			margin: 0.2rem 0.1rem;
		}*/

		.fileinfo {
			font-size: 0.8em;
		}
	}
}

/*div.modal.modal-scroll {
	display: block;
	overflow: visible;
	>div.modal-dialog {
		transform: none;
		position: fixed;
		top: 5%; right: 5%; bottom: 5%; left: 5%;
		margin:0;

		>div.card.card-edit {
			height: 100%;
			width: 100%;
			>div.card-block {
				height: 100%;
				overflow-y: scroll;
				padding: 0;
				margin: -1px;
			}
		}
	}
}*/

div.Modal.MDModal {
	>div.modal.modal-scroll {
		>div.modal-dialog {
			>div.card.card-edit {
				>div.card-block {
					padding: 0.5em 1em;
				}
			}
		}
	}
}

div.MDModal {
	div.DraftEditor-root {
		border: 1px solid #eee;
	}
}

div.MeasureTextModal, div.RiskTextModal {
	textarea {
		margin-bottom: 0.5em;
	}
}

/* Help Editor */
div.EditorButtons {
	margin-bottom: 0.5em;
	>button {
		padding: 0.1em 0.2em;
	}
}

div.InlineHelp {
	div.InlineHelpButton {
		width: 100%;
		text-align: right;
		margin-bottom: 1rem;
		min-height: 1rem;
		>*{
			position: absolute;
			right: 0;
		}
	}

	div.inlinehelp {
		margin: 0.5rem 0.75rem 0.25rem;
	}

	.InlineHelpExpand {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
	}

	img {
		display: block;
		margin: 0 auto;
		max-width: 95%;
	}

	@media only screen and (max-width: 768px) {

		img {
			width: 95%;
			margin: 0 2.5vw;
		}

	}
}

div.HelpModal {
	&.helpright {
		float: right;
	}
}

div.ReportIntro {
	color: $btndarkborder;
}

div.imgupload {
	>div.uploadButtons {
		>input{
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}
		>label {
			margin: 0 0.5rem;
		}

		.close {
			margin-top: -0.6rem;
			margin-right: -0.6rem;
		}
	}
}

input.upload{
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

label.upbtn {
	margin: 0;
	/*padding: .5rem .75rem;*/
}

div.imgpreview {
	white-space: normal;
	>div {
		margin-top: 0.5em;
		display: inline-block;
		vertical-align: top;
	}
	img {
		height: 6em;
		width: auto;
		padding: 0.1em;
	}
	button {
		position: relative;
		left: -1em;
	}
}

div.Audit {
	/*margin: -1px;*/

	>div.input-group {
		>div.form-control, .input-group-addon {
			border-radius: 0;
		}

		>div.input-group-addon {
			border-radius: 0;
			>button {
				border-radius: 0;
			}
		}
	}
}

div.modal.modal-scroll {
	overflow: visible;
	
	>div.modal-dialog {
		position: fixed;
		top: 5%; right: 5%; bottom: 5%; left: 5%;
		width: 90%;
		max-width: 90%;

		>div.card.card-edit {
			height: 100%;
			>div.card-block {
				height: 100%;
				overflow-y: scroll;
				/* padding: 0;   !! Experiment*/
				/*margin: -1px;*/
				>* {
					margin-left: 0;
					margin-right: 0;
				}

				&.padding {
					padding: 1vw;
				}
			}
		}
	}
}

div.AuditModal {
	>div.modal {
		>div.modal-dialog {
			>div.card.card-edit {
				>div.card-block {
					padding: 0 1px;
				}
			}
		}
	}
}

div.AttachModal>div.btn-group>div.Modal>div.modal>div.modal-dialog>div.card.card-edit {
	>div.card-block {
		padding: 0.5em;

		>div.AttachNote {
			margin: 0.5em;
		}
	}
}

div.Modal.WizardModal {
	>div.modal {
		>div.modal-dialog {
			>div.modal-content {
				.card-block {
					padding: 0.5rem;
				}
			}
		}
	}

	p {
		margin: 1rem 0 0.5rem;
		color: $highlightText;
	}

	.input-group-text {
		background-color: $btnbkcol;
	}

}

//.input-group .form-control {width: 100%}

/*.mslider {
	float: right;
	height: 1em;
}*/

/* Areas */

div.EditableA {
	display: inline-flex;
	/*min-width: 15rem;*/
	align-items: center;
	text-align: left;
	/*word-break: break-all;*/
	word-wrap: break-word;
	&[contenteditable=true]:not(.solid) {
		background-color: #fff;
		cursor: text;
	}
}

/*
ul.nav.nav-tabs.building-tabs {
	padding: 0.5em 0.5em 0;
}

.nav-item.building-tab {
	outline: none;
	margin-bottom: -1px;

	>a.nav-link.active {
		background-color: #eceeef;
		border-bottom: 1px solid #eceeef;

		&[contenteditable=true] {
			background-color: #fff;
		}
	}
}

.building>div.card-block>div.tab-content>div.tab-pane{
	padding-top: 0;
}

div.tab-content.card.level-card {
	border: none;
	>div {
		padding: 0;
	}
}


div.Level {
	padding: 0;

	>.topicons {
		>span {
		margin: 0;
		}
	}

	div.prompt.levelprompt {
		margin: 1em;
	}

	div.LevelSummary {
		margin-bottom: 0.5em;
	}

	div.card-header {
		.LevelSummariser {
			border-top: 0;
			border-bottom: 0;
		}
	}

	>.level-items {
		padding: 0.2em;
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		margin-bottom: -1px;
	}

	.form-group {
		display: inline-block;
		max-width: 30em;
	}

	li.LevelMainTab>a.nav-link {
		font-weight: bold;
	}

	.LevelAttr {
		nav.navbar {
			padding: 0.5em 0 0;
		}

		div.tab-pane {
			padding: 0;
		}

	}

	div.LevelControls {
		text-align: right;
		margin: 0.75em 0 0.5em;
		span {
			display: block;
			float: left;
		}
	}
}

div.FloorCategories {
	margin-bottom: 1em;
}

div.LevelArea {
	margin-bottom: 0.5em;
	max-width: 30em;
}

div.AreaEscapeCheck {
	margin-top: 1em;
}

div.EscapeCheck {
	margin-top: 0.5em;
}

div.topdata {
	padding: 0.3em 0.3em;
	border-bottom: 1px solid rgba(0,0,0,.125);

	>div {
		padding: 0.3em;
	}
}

.areanew {
	margin-bottom: 1em;
}

.bicons {
	float: right;
}

.level {
	min-height: 3em;
}

div.card.card-edit.Room {
	display: inline-block;
	width: 20em;
	margin: 0.3em;

	>div.card-header {
		
	}

	>div.card-block {

		div.top-data {

		}
	}
}
*/

/*div.input-group.Room2 {

	margin: -1px;

	.input-group-addon {
		border-radius: 0;

		&.icon {

			padding: .5rem .75rem;
		}

		>button {
			border-radius: 0;
		}

		>div.Modal {
			div.card-block {
				padding: 0;
			}
		}
	}

}*/

/*div.input-group.fwinput, div.BuildingModal>div.Level {
	margin: -1px;
	>div.input-group {
		border-width: 1px;
		padding: 0;
		>input {
			border-width: 0 1px;
			border-radius: 0;
		}
		>span {
			border-width: 0;
		}
	}
	>.input-group-addon {
		border-radius: 0;
	}
	>input {
		border-radius: 0;
	}
}

div.Summariser {
	.Summariser-icon {
		display: inline-block;
		padding: 0 0 0 .5rem;
	}
	.Summariser-value {
		display: inline-block;
		padding: 0 .5rem;
	}
}

div.AreaItem {
	margin: -1px;

	div.input-group {
		>span, >div, >button {
			border-radius: 0;
		}
	}
}


div.AreaExtinguisher {
	>div.Measure {
		>.AEtitle {
			min-width: 6rem;
		}
		>.AEcategory {
			min-width: 4rem;
		}
	}
}
*/

.icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	font-size: 2rem;
	line-height: 2.5rem;
	width: 2.3rem;
	text-align: center;
	vertical-align: middle;
	&:before {
		vertical-align: middle;
	}
}

div.BaseFolder {
	
	div.searchbox {
		>input {
			border-radius: 2em!important;
			max-width: 25rem;
			height: 100%;
			padding: 0 1rem!important;
		}
	}

	>div.card.card-edit {
		border: none;
	}

	button.Site {
		display: inline-block;
		background: none;
		width: 7em;
		margin: 0.3em 0.5em;
		text-align: center;
		cursor: pointer;
		border: 1px solid #fff;
		border-radius: 0.25rem;
		vertical-align: top;
		padding: 0.2rem;

		>div >span {
			color: $btndarkborder;
		}

		&:disabled {
			cursor: default;
		}

		&:hover:not(:disabled) {
			/*border-color: #ccc;*/
			color: $stnhover;

			>div >span {
				color: $stnhover;
			}

		}

		>div.title {
			margin-top: 0.5rem;
			font-size: 100%;
		}
		/*>div.title {
			font-size: 1.1em;
		}*/
	}

	div.sitegridbox {
		margin-top: 1em;
	}

	div.sitegrid {

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		/*grid-gap: 1px;*/

		/*background-color: $btnbkcol;*/

		overflow-x: auto;
		overflow-y: hidden;

		border: 2px solid $laurablue2;

		>* {
			background-color: #fff;
			padding: 0.1rem 0.2rem;
			font-size: 0.9rem;
		}

		div.Modal {
			font-size: 1rem;
		}

		/*>div.sitegridrow {

			display: grid;
			grid-auto-columns: auto;
			grid-auto-flow: column;
			background-color: $btndarkborder;
			grid-gap: 1px;
			
			>* {
				background-color: #fff;
				padding: 0.1rem;
			}
		}*/

		>div.gridheader {
			border-bottom: 2px solid $laurablue2;
			background-color: $btnbkcol;
			
			word-break: keep-all;
			hyphens: none;
			white-space: normal;
			overflow-wrap: normal;

			text-align: center;

			>span {
				&::before {
					display: block;
					height: 100%;
				}
			}

			>button {
				color: #000;
				height: 100%;
			}
		}

		>div:not(.gridheader) {
			border: 1px solid $btnbkcol;
			margin-left: -1px;
			margin-bottom: -1px;
		}


    >div, >div>* {
      >button {
        background: none;
        border: 0;
        color: #007bff;

        &:hover:not(.FButton) {
          text-decoration: underline;
        }
      }
    }
  }
  
  div.EditUsers {

    div.EditUsers-heading {
      margin: 0.5rem 0;
	}
	
	div.input-group.AddUser {
		border: none;
	}

  }
}


div.modal-content.card.card-edit {
	>div.card-block {
		>div.card.card-edit.archivebox {
			display: inline-block;
			margin-left: 0.5rem;
		}
	}
}

@keyframes bkthrob {
	0% {
		background-color: #fff;
	}
	50% {
		background-color: $btnbkcol;
	}
	100% {
		background-color: $btnbkcol;
		opacity: 75%;
	}
}

.GreyBox {
  width: 5rem;
  background-color: $btnbkcol;
  border: 1px solid $btnborder;
  border-radius: 0.5rem;
  height: 5rem;
  margin: 0.5rem;
  vertical-align: top;
  padding: 0.2rem 0.5rem;
  text-align: center;
  display: inline-block;

  &.highlight {
	  border-color: $laurablue;
  }

  &:right {
    float: right;
  }

  &.large {
	  width: 10rem;
  }

  &.xlarge {
	width: 15rem;
	height: auto;
	min-height: 8rem;
}

  &.medium {
	  width: 8rem;
  }

  &.popout {
	  /*height: auto;*/
	  >div.GreyBox-main {
		  /*display: none;*/
	  }
  }

  div.GreyBox-main {
	font-size: 1rem;
	min-height: 2.5rem;
	>input {
		display: inline-flex;
	}
  }
  div.GreyBox-subtitle {
	font-size: 0.8rem;
	min-height: 1em;
  }

  &:hover:not(.notButton) {
    background-color: $btnbkcolhover;
  }

  &.notButton {
    cursor: default;
  }


}

div.loadSurvey {
	min-height: 3rem;

	button.loadSurveyButton {
	&:hover {
		background-color: $btnbkcolhover;
	}
	}
}

div.SiteData {

	span.SiteData-title {
		font-size: 1.2em;
	}

	>div.card.card-edit {
		border: none;

		>div.card-header {
			background-color: transparent;
			border: none;

			>* {
				background-color: transparent;
			}
		}

		>div.card-block {
			/*padding: 0.3em;*/
			min-height: 10em;

			/*>div.card.card-edit {
				display: inline-block;
				margin: 0.3em;
				max-width: 25em;
				width: 100%;

				>div.card-block {
					padding: 0.5em;
					min-height: 4rem;

					>div.card {
						display: inline-block;
						max-width: 15em;
						width: 100%;
						margin: 0.3em;
						>div.card-block {
							padding: 0.3em;
						}
					}
				}
			}*/

			/*>div.card.card-edit.SiteImage {
				>div.card-block {
					text-align: center;

					>img.SiteImageImg {
						max-width: 100%;
						max-height: 15rem;
					}
				}
			}

			>div.card.card-edit.SiteInfo {
				>div.card-block {
					padding: 0;
				}
			}*/
		}
	}

	div.Assessment {
		margin-top: 0.5em;
		>div.input-group.loadSurvey {
			border: 1px solid $btnborder;
			border-radius: 0.5rem;
			/*background-color: #e8f0f8;*/
			background-color: $headingHighlightBackground;

			cursor: pointer;

			> * {
				background: none;
			}

			&:hover {
				/*background-color: #d4e3f2;*/
			}

			>button {
				background-color: transparent;
			}
			>div.input-group-append {
				button.OpenAssessment {
					background-color: #007bff;
					border-color: #007bff;

					&:hover {
						background-color: #0069d9;
						border-color: #0062cc;
					}
				}

				div.Assessment-start {
					margin-top: 0.1rem;
					margin-right: 0.5rem;

					>.FInfoMain {
						color: $laurablue;
					}
				}
			}
		}

		>div.Assessment-body {

			min-height: 6rem;

			> * {
				vertical-align: top
			}
			/*padding: 0 0 1rem;*/

			>div.docpreview {
				display: inline-block;
			}

			/*>div.btn-group.ReportArchive {
				display: inline-block;

				>button.btn.FButton {
					margin: 0.5rem;

					>span.FButtonIcon {
						height: 3rem;
						>span {

							&:before {
								font-size: 3em;
							}
						}
					}

					>span.FButtonText {
						font-size: 1em;
						margin-top: 0.75em;
					}

					>span.FButtonOverlay {
						top: calc(50% - 2.5rem);
						font-size: 2rem;
					}
				}
			}*/
		}

		.cloneAssessment {
			margin-top: 1rem;
			max-width: 40rem;
			float: right;
		}
	}

}

div.input-group.Field {
	/*margin: -1px;*/

	>span {
		padding: 0.5rem 0.75rem;
	}
	>span, input {
		border-radius: 0;
	}
}

div.EscapeArea {
	/*margin: -1px;*/
	>div.input-group {
		input.routelength {
			max-width: 4em;
		}
		>div, span, button {
			border-radius: 0;
		}
	}
}

div.CategoryList {
	margin-top: 0.5em;
	>div.Category {
		&.live {
			margin: -1px 0 0;
			border-radius: -0;
			>div.card-header.input-group {
				border: 0;
				>span.title {
					background-color: #fff;

					&.selected {
						font-weight: bold;
					}
				}
			}
		}
	}
}

nav.navbar {

	>ul.nav.nav-tabs {
		flex-wrap: nowrap;
		width: 100%;
		>li.nav-item {
			
			white-space: nowrap;
			display: inline-flex;

			>button.nav-link {
				display: inline-flex;
				align-items: center;

				&::before {
					font-size: 1.5rem;
				}

				outline: none;
				border-bottom: solid 1px #dee2e6;

				&:not(.active) {
					background-color: $headingHighlightBackground;
					color: $btndarkborder;

	    			&:hover {
						border: solid 1px #dee2e6;
					}
				}
				&.active {
					border-bottom-color: #fff;
					cursor: default;
				}
				>span.tabTitle {
					white-space: nowrap;
					overflow: hidden;
					padding-left: 0.3em;
				}

			}


		}
	}
}

/* Subfield Editor */
div.SFE-card {
	margin-top: 0.5rem;
}


/* Action Summary */
div.asmMeasure {
	background-color: $warning;
	padding: 0.1em 0.2em;
	margin-bottom: 0.2em;

	&.closed {
		background-color: $ok;
	}
}

.asmClosed {
	border: solid 1px #ccc;
	background-color: $ok;
	margin-right: 0.5em;
	padding: 0.2em 0.4em;
}

.asmCloseButton {
	padding: 0.1em;
	margin-right: 0.5em;
}

ul {
	padding-left: 1.5em;
}

div.ActionSummaryPane {
	div.reportButton {
		margin-top: 1rem;
		text-align: center;
	}

	img.imgparent {
		margin-top: 0.2rem;
		width: 10rem;
		height: auto;
	}

	>div.SurveySection.card.card-edit {
		>div.input-group.card-header {
			>div.form-control.title.ASPclosedactions {
				background-color: $btnbkcol;
			}
		}
	}

	div.riskident {
		margin: 0.5rem 0 0.5rem;
		font-weight: bold;
	}

	div.note {
		margin-top: 1.5rem;
		font-style: italic;
	}

	h5 {
		margin-top: 1rem;
	}

	div.SummaryMeasure {
		/*margin: 1rem 0;*/
		padding: 0.1rem 0 0.8rem 0;

		/*border-top: 1px solid $btnbkcol;*/

		/* background-color: $rootbackground; */

		>.gr1 {
			display: grid;
			grid-template-columns: 12rem 1fr;

			>div {
				padding: 0.5rem 0.6rem;

				&.head {
					
				}
			}

			@media only screen and (max-width: 768px) {
				display: block;

				>div {
					padding: 0.1rem 0.5rem;
				}

				div.EditableA {
					width: 100%;
				}
			}
		}

		/*}>.gr2 {
			display: grid;
			grid-template-columns: 1fr 12rem;

			background-color: $headingHighlightBackground;

			>div {
				padding: 0.5rem 0.6rem;
			}

		}*/

		.EditableA {
			min-width: 15rem;
		}

		.sp {
			grid-column: 1 / span 2;
		}

		.summarytitle {
			font-weight: bold;
		}

		input {
			background-color: $rootbackground;
		}

		.docpreview {
			margin-bottom: 1rem;
		}

		.AS-info {
			margin-bottom: 0.75rem;
		}

		.cpform {
			display:flex;
			padding-right: 0.5rem;
			
			>*:not(:last-child) {
				padding-right: 0.5rem;
			}

			/*font-size: 0.8rem;*/

			>:first-child {
				margin-left: auto;
			}

			>input {
				display: inline;
			}

			>.asf {
				display: inline;
				padding-left: 0.5rem;
				
				>input {
					display: inline;
				}
			}

			@media only screen and (max-width: 768px) {
				margin-top: 0.5rem;
			}
		}

		div.Modal.ASModal {
			div.card-block {
				textarea {
					margin: 0.5em 0 1em;
				}

				div.input-group {
					border: 0;
				}

			}
		}
	}

	div.ExecutiveSummary.SurveySection.card.card-edit {
		>div.card-block {
			padding: 1rem 0;
		}
	}

	div.Matrix {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		text-align: center;
		border: all;
		max-width: 50rem;
		margin: 1rem auto;
		border: 1px solid $btndarkborder;
		align-items: center;

		>div {
			border: 1px solid $btndarkborder;
			height: 3rem;
			line-height: 2rem;
			padding: 0.3rem;

			&.active {
				border: 3px solid $btncol;
				font-weight: bold;
			}
		}

		.rl1 {background-color: #bfff00}
		.rl2 {background-color: #fff8d8}
		.rl3 {background-color: #ffdd76}
		.rl4 {background-color: #ffc000}
		.rl5 {background-color: #dc3545}
	}
	
}

div.MButtons {
	text-align: center;
	margin-bottom: 1rem;

	>div {
		display: inline-block;

		>div+div {
			margin-left: 0.5rem;
		}
	}
}

div.LogAudit {
	/*&.ptype {
		border: 1px solid red;
	}*/

	>div.ztitle {
		cursor: pointer;
	}

	div.LogAuditRow {
		&.ptype {
			border: 1px solid red;
		}
	
	}
	
}

.LogAudit-addrow {
	padding-top: 0.5em;
	float:right;
}

div.LogField {
	>div.input-group.input-group-edit {
		min-height: 3.5rem;
		border: 0;

		>div.input-group-prepend {
			> a{
				min-width: 10em;
			}
		}

	 	>div.input-group-append.fill {
			position: absolute;
			right: 0;
		}
	}

	>div.InlineHelp {
		margin: 1rem 0 0 0;
	}
}

div.LogGrid {
	display: grid;
	grid-gap: 1px;

	background-color: $btnbkcol;
	border-bottom: 1px solid $btnbkcol;

	text-align: center;

	>* {
		background-color: #fff;
		height: 100%;
		display: grid;
		grid-template-columns: 1fr;
		align-items: center;
	}


	>div.LogGridHead {
		border-top: 1px solid $btnbkcol;
		border-bottom: 1px solid $btnbkcol;

		position: sticky;
		top: 0px;
		text-align: center;
		z-index: 10;

		&.multi {
			background-color: $btnbkcol;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 1px;
			text-align: center;

			>:first-child {
				grid-column: 1 / span 2;
				padding-bottom: 0.3rem;
				background-color: #fff;
				height: 100%;
			}

			>:not(:first-child) {
				background-color: #fff;
				padding: 0.2rem 0;
				height: 100%;
			}
		}
	}

	.input-group-append {
		margin: 0;
	}

	div.TextCheck {
		display: grid;
		grid-template-columns: 1fr 1fr;
		text-align: center;
	}
}

span.hf {
	color: red;
}

div.APTemplateModal {
	>div.modal {
		>div.modal-dialog {
			>div.modal-content {
				cursor: default;

				>div.card-block {
					>div {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}

div.FireActionPlan {
	
	
	&.input-group {
		border-top-width: 1px;
	}

	cursor: pointer;
	&:hover {
		background-color: $SubSection;
	}

	div.FireActionPlan-card {
		border: 0;
	}

	div.Modal {

		div.card-block {

		}

	}
}

div.FormCombo {
	padding: 0;
}

div.FireActionPlan {

	.readonly {
		color: $btndarkborder;
		cursor: default;
	}

	.APtitle {
		font-weight: bold;
	}

	div.APField.input-group {
		border: 0;

		.APtitle {
			font-weight: bold;
			&:not(.readonly):after {
				content: ":"
			}
		}
	}
}


input[type=text], input[type=password], input[type=number], input[type=email], input[type=date], input[type=file], input[type-tel], input[type=url], textarea, div.EditableA[contenteditable=true] {
	border: 1px solid $btnbkcol!important;
	margin: 1px!important;
	padding: 1px 0.75em!important;
	border-radius: 1em!important;

}

div.ER-heading.input-group {
	border: 0;
}

div.ER-body, div.EP-body {
	>.GreyBox {
		min-height: 8rem;
	}
}

.editdatecheck {
	color: $laurablue2;
}

.editdateupdate {
	color: $laurablue2;
	text-align: right;
}

.editdateupdate:hover {
	color: $fsred;
}



div.fsmarkdown {
	h1, h2, h3, h4, h5, h6 {
		text-align: center;
	}
}


@media only screen and (min-width: 769px) {
	.desktop-hide {
		display: none;
	}
}

/*@media only screen and (max-width: 1080px) {
    body {
    	font-size: calc(1em+0.5vw);
    }
}*/

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
   
	/*font-size: 2vw;*/

	div.BaseFolder {
		div.sitegrid {

			>* {
				font-size: 0.7rem;
			}
		}
	}
	
	.input-group-text {
		padding-left: 0.75vw;
		padding-right: 0.75vw;
	}

	#rightbuttons {
		top: 0.5em;
	
		:before {
			font-size: 1.5em;
		}
	}

    .mobile-hide {
    	display: none;
    }

    body {
    	font-size: 1rem;
    	padding-top: 40px;
	}
	
	#logoutbutton {
		right: 0em;
	}


    div.surveyedit {
    	top: auto;
		bottom: 0.5em;
		right: 0.5em;
    }

	span.FButtonText{
		display: none;
	}

	nav.navbar {
		padding: 3px 0 0 0;

		>ul.nav.nav-tabs {
			>li.nav-item {
				>button.nav-link {
					padding: 0.5rem 0.7rem;
					&.active {
						border-bottom-color: #fff;
					}
					&:not(.active) {
						>span.tabTitle {
							display: none;
						}
					}
					>span.tabTitle {
						overflow: visible;
						font-size: 1.1rem;
					}
				}
			}
		}
	}

	label.radio-addon {
		span.radioTitle {
			display: none;
		}
	}

	div.tab-pane {
		margin: 1em 0em;
	}

	div.card.card-edit.SiteData {
	
		>div.card-block {
			padding: 0.3em;
			min-height: 10em;

			/*>div.card.card-edit {
				display: block;
				margin: 0.3em;
				max-width: 100%;
			}*/
		}
	}

	.form-container {
		width: 100%;
	}


	/* Hack for mobile */
	.APField.input-group {
		flex-direction: column;

		div.FormCombo.input-group {
			width: 100%;
		}

		span {
			width: 100%;
		}

		.dropdown-menu-right {
			right: 0;
		}
	}

}

/* IE hack!! */

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
	.bluebutton {
		margin: 4em 0 2em;
	}
 }